<template>
    <site-header :phone="phone"></site-header>
    <div class="bg-[#15131b]">
        <div class="container">
            <div class="pt-5"></div>

            <h1 class="mt-10">Оставьте заявку и получите персональное предложение от T-Private</h1>
            <div class="text-center mt-5">
                Решения для крупного капитала: доверительное управление, долгосрочная стратегия инвестиций и налоговый консалтинг.<br>
                Лучшие практики private banking усилены нашей цифровой экспертизой
            </div>
        </div>

        <frame-form class="mt-8" url="https://tbank.ru/tinkoff-premium/cards/debit-cards/tinkoff-black-premium/iframe/form"/>

        <div class="container">
            <div class="grid grid-cols-3 sm:grid-cols-1 gap-12 sm:gap-6 mt-2">
                <div class="bg-[#312f36] rounded-3xl px-7 py-9">
                    <img src="@/assets/images/i1-1.svg">
                    <h3 class="mt-11">Персональный менеджер&nbsp;в&nbsp;инвестициях</h3>
                    <div class="mt-3">Поддержка в чате 24/7, подбор портфеля, аналитика</div>
                </div>
                <div class="bg-[#312f36] rounded-3xl px-7 py-9">
                    <img src="@/assets/images/i1-2.svg">
                    <h3 class="mt-11">Безлимитный доступ в&nbsp;бизнес-залы</h3>
                    <div class="mt-3">Комфортные лаундж-зоны с едой и напитками по всему миру</div>
                </div>
                <div class="bg-[#312f36] rounded-3xl px-7 py-9">
                    <img src="@/assets/images/i1-3.svg">
                    <h3 class="mt-11">Максимум кэшбэка в&nbsp;Т-Банке</h3>
                    <div class="mt-3">До 200 000 рублей, миль или бонусов карты за покупки каждый месяц</div>
                </div>
            </div>

            <h2 class="mt-24">Управляйте капиталом<br>с командой экспертов</h2>
            <div class="mt-6 text-center">Разработаем долгосрочную инвестиционную стратегию исходя из ваших финансовых и жизненных целей</div>

            <div class="grid grid-cols-2 gap-12 mt-12 sm:gap-6 sm: grid-cols-1">
                <div class="bg-[#312f36] rounded-3xl px-7 py-9">
                    <h3 class="">Бесплатные консультации юристов</h3>
                    <div class="mt-3">Помощь по всем правовым вопросам, составление жалоб, претензий и договоров, юридический чекап</div>
                </div>
                <div class="bg-[#312f36] rounded-3xl px-7 py-9">
                    <h3 class="">Безлимитные налоговые консультации</h3>
                    <div class="mt-3">Оптимизация налоговой нагрузки. Консультации по декларациям, документам, вопросам наследования</div>
                </div>
            </div>
            <div class="grid grid-cols-3 gap-12 mt-12 sm:gap-6 sm:grid-cols-1">
                <img class="bg-[#312f36] rounded-3xl col-span-2" src="@/assets/images/posib.webp" alt="pos">
                <div class="py-8">
                    <h3 class="">Больше возможностей для инвестиций</h3>
                    <custom-list class="mt-4" :items="[
                        'Сниженная комиссия за сделки с акциями и облигациями',
                        'Торг внебиржевыми инструментами, покупка недвижимости в РФ и за рубежом'
                    ]" type="dash"/>
                    <h3 class="mt-12">Золотые слитки с обратным выкупом</h3>
                    <div class="mt-3">Покупайте слитки в приложении банка: доставим их на бронемобиле под охраной</div>
                    <h3 class="mt-12">Назначьте доверенное лицо</h3>
                    <div class="mt-3">Для управления счетами и инвестициями: в приложении банка без посещения офиса</div>
                </div>
            </div>

            <h2 class="mt-24">Металлическая карта с широкими возможностями</h2>
            <div class="grid grid-cols-2 gap-12 mt-12 sm:gap-6 sm:grid-cols-1">
                <div class="bg-[#312f36] rounded-3xl px-7 py-9">
                    <h3 class="">Снимайте без комиссии</h3>
                    <div class="mt-3">До 10 000 000 ₽ в месяц в банкоматах партнеров и безлимитно в банкоматах Т-Банка</div>
                </div>
                <div class="bg-[#312f36] rounded-3xl px-7 py-9">
                    <h3 class="">Переводите бесплатно</h3>
                    <div class="mt-3">До 500 000 ₽ в месяц по номеру карты на карты других банков, безлимитно на карты Т-Банка</div>
                </div>
                <div class="bg-[#312f36] rounded-3xl px-7 py-9">
                    <h3 class="">Заказывайте наличные куда удобно</h3>
                    <div class="mt-3">Доставим наличные или заберем деньги для пополнения в Сочи, Москве и области, Санкт-Петербурге и области. Инкассация проходит по всем правилам банковской безопасности</div>
                </div>
                <div class="bg-[#312f36] rounded-3xl px-7 py-9">
                    <h3 class="">Безлимитные налоговые консультации</h3>
                    <div class="mt-3">Премиальная карта Mir Supreme действует в Абхазии, Армении, Беларуси, Вьетнаме, Казахстане, на Кубе, в Кыргызстане, Таджикистане и Южной Осетии</div>
                </div>
            </div>
            <yellow-button class="block mx-auto mt-12 text-[#655c32]" @click="gotoForm">Оставить заявку</yellow-button>

            <h2 class="mt-24">Максимум комфорта каждый день</h2>
            <div class="grid grid-cols-3 gap-12 mt-12 sm:gap-6 sm:grid-cols-1">
                <div class="py-8">
                    <h3 class="mt-4">Премиум-страховка в путешествиях</h3>
                    <div class="mt-3">На 5 человек, с покрытием до 200 000 $ на каждого. Для активного отдыха и спорта по всему миру</div>
                    <h3 class="mt-12">Консьерж-сервис</h3>
                    <div class="mt-3">First Concierge организуют праздник, поездку, доставку из магазина</div>
                    <h3 class="mt-12">Отдых и развлечения</h3>
                    <custom-list class="mt-4" :items="[
                        'Персональный сомелье и скидки от партнеров',
                        'Бесплатные поездки с личным водителем от сервиса Wheely',
                        'Приглашения на спортивные и культурные мероприятия от Т-Банка и партнеров'
                    ]" type="dash"/>
                </div>
                <img class="bg-[#312f36] rounded-3xl col-span-2" src="@/assets/images/comfort.webp" alt="pos">
            </div>

            <h2 class="mt-20">Лучший розничный онлайн-банк в мире</h2>
            <div class="mt-6 text-center">По версии международного журнала Global Finance в 2020 году</div>
            <div class="grid grid-cols-3 gap-12 mt-12 sm:gap-6 sm:grid-cols-1">
                <div class="bg-[#312f36] rounded-3xl px-7 py-9">
                    <img src="@/assets/images/i2-1.svg">
                    <div class="mt-10">Входим в число 13 системно значимых кредитных организаций Банка России</div>
                </div>
                <div class="bg-[#312f36] rounded-3xl px-7 py-9">
                    <img src="@/assets/images/i2-2.svg">
                    <div class="mt-10">15 лет в финансовом секторе. Ни одного убыточного года начиная с 2009</div>
                </div>
                <div class="bg-[#312f36] rounded-3xl px-7 py-9">
                    <img src="@/assets/images/i2-3.svg">
                    <div class="mt-10">Более 40 млн клиентов в России выбрали&nbsp;Т-Банк</div>
                </div>
            </div>

            <a class="bg-[#312f36] rounded-3xl mt-12 flex sm:flex-col hover:scale-105 transition-all duration-500" href="https://www.tbank.ru/tinkoff-premium/">
                <div class="grow flex p-8 flex-col">
                    <h3>Попробуйте Premium от Т-Банка</h3>
                    <div class="mt-4">Премиальное обслуживание с повышенным доходом, комфортом<br>и привилегиями</div>
                    <div class="grid grid-cols-3 gap-12 mt-8 grow sm:hidden">
                        <div>
                            <h3>0 или 1990 ₽</h3>
                            <div class="mt-1">В месяц за&nbsp;<nobr>премиум-сервис</nobr>
                            </div>
                        </div>
                        <div>
                            <h3>До&nbsp;60&nbsp;000&nbsp;₽</h3>
                            <div>Ежемесячный кэшбэк за покупки</div>
                        </div>
                        <div>
                            <h3>До&nbsp;100&nbsp;000&nbsp;₽</h3>
                            <div>Бесплатные переводы по номеру карты</div>
                        </div>
                    </div>
                    <yellow-button class="block mr-auto text-[#655c32] sm:mt-8">Узнать больше</yellow-button>
                </div>
                <img src="@/assets/images/try.webp" alt="try">
            </a>
        </div>
        <div class="mt-36">&nbsp;</div>
    </div>
    <site-footer :phone="phone" :product-links="menu" :disclaimer="disc" copyright="АО «Т-Банк», официальный сайт, универсальная лицензия ЦБ РФ № 2673
"></site-footer>
</template>

<script>

import '@/assets/tailwind.css';
import SiteHeader from "@/components/site-header-v2";
import SiteFooter from "@/components/site-footer-v2";
import FrameForm from "@/components/frame-form.vue";
import CustomList from "@/components/custom-list.vue";
import YellowButton from "@/components/yellow-button.vue";

export default {
    name: 'App',
    components: {YellowButton, CustomList, FrameForm, SiteHeader, SiteFooter},

    data: () => ({
        phone: process.env.VUE_APP_PHONE,
        urlParams: '',
        disc: 'Лучший розничный онлайн-банк (Best Consumer Digital Bank) по версии премии Digital Bank Award (Премия цифровых банков) журнала Global Finance (Глобал Финанс) 2020 года по критериям: стратегия онлайн-привлечения и обслуживания клиентов, успешное привлечение клиентов с помощью цифровых предложений, рост клиентов, ассортимент продуктов, получение выгоды от цифровых инициатив, дизайн сайта и его функциональность.<br>' +
            'Private — Прайвэт<br>' +
            'Лаундж-зона — зона отдыха<br>' +
            'Чекап — комплексная проверка<br>',
        menu: [
            {text: 'Условия T-Private', link: require('@/assets/docs/1.pdf')},
            {text: 'Условия маркетинговой акции «Отмена платы за сервис «Покупка/продажа слитков драгоценных металлов»', link: require('@/assets/docs/2.pdf')},
            {text: 'Правила оказания сервиса «Покупка/продажа слитков драгоценных металлов»', link: require('@/assets/docs/3.pdf')},
            {text: 'Условия предоставления сервиса Premium от Т-Банка', link: require('@/assets/docs/4.pdf')},
            {text: '«Т-Банк Таргет»', link: require('@/assets/docs/5.pdf')},
            {text: 'Тарифный план для сервиса «Premium»', link: require('@/assets/docs/6.pdf')},
            {text: 'Условия маркетинговой акции «Компенсация ресторана в аэропорту c T-Private»', link: require('@/assets/docs/7.pdf')},
        ],
    }),

    methods: {
        gotoForm() {
            window.scrollTo({
                top: document.getElementById('form-iframe').offsetTop,
                left: 0,
                behavior: "smooth",
            });
        }
    },

    created() {
        this.urlParams = window.location.search;
    },
}

</script>

<style lang="scss">

@font-face {
    font-family: 'TinkoffSans';
    src: url('assets/fonts/TinkoffSans.woff2');
    font-weight: 100;
}

@font-face {
    font-family: 'TinkoffSans';
    src: url('assets/fonts/TinkoffSans_Medium.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TinkoffSans';
    src: url('assets/fonts/TinkoffSans_Bold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'haas';
    src: url('assets/fonts/NeueHaasUnicaW1G-Regular.woff2');
}

@font-face {
    font-family: 'Font Awesome';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url("assets/fonts/fa-regular-400.eot");
    src: url("assets/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("assets/fonts/fa-regular-400.woff2") format("woff2"), url("assets/fonts/fa-regular-400.woff") format("woff"), url("assets/fonts/fa-regular-400.ttf") format("truetype");
}

@font-face {
    font-family: 'Font Awesome';
    font-style: normal;
    font-weight: 900;
    font-display: block;
    src: url("assets/fonts/fa-solid-900.eot");
    src: url("assets/fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("assets/fonts/fa-solid-900.woff2") format("woff2"), url("assets/fonts/fa-solid-900.woff") format("woff"), url("assets/fonts/fa-solid-900.ttf") format("truetype");
}

body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 13px;
    font-family: haas, pragmatica, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
    line-height: 22px;
    color: #ffffff;
    font-kerning: normal;
    text-rendering: optimizeLegibility;
    @media screen and (max-width: 575px) {
        font-size: 15.5px;
    }
}

.container {
    @media screen and (max-width: 575px) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

h1, h2, h3 {
    font-family: 'TinkoffSans', sans-serif;
    font-weight: 400;
}

h1, h2 {
    font-weight: 600;
    font-variant-ligatures: no-contextual;
    font-size: 42px;
    line-height: 50px;
    letter-spacing: 0;
    text-align: center;
    @media screen and (max-width: 575px) {
        font-size: 22px;
        line-height: 25px;
        font-weight: 400;
    }
}

h3 {
    font-size: 24px;
    line-height: 28px;
    @media screen and (max-width: 575px) {
        font-size: 19px;
        line-height: 24px;
    }
}

.header {
    @apply mt-24 w-2/3 mx-auto text-center;
}

::selection {
    background: rgba(255, 221, 45, .32) !important;
    color: rgba(0, 0, 0, .8) !important;
}

.numbers {
    font-family: TinkoffSans, pfhighway, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
    font-weight: 100;
}

.splide__pagination {
    bottom: -30px;

    li {
        button {
            background: #DDDFE0;

            &.is-active {
                background: #FFDD2D;
            }
        }
    }
}

.splide__arrow {
    background: #eee;

    svg {
        fill: #336fee;
    }

    &:disabled {
        display: none;
    }

    &.splide__arrow--next {
        right: -3em;
    }

    &.splide__arrow--prev {
        left: -3em;
    }
}

.accordion-list {
    width: 100%;

    .accordion-item {
        --content-height: 0px;
        height: calc(var(--summary-height) + 26px);
        overflow: hidden;
        transition: height 300ms ease-in-out;

        > .accordion-item__summary {
            padding: 12px 0;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            transition: color 300ms ease-in-out;

            &::-webkit-details-marker {
                display: none;
            }

            > .accordion-item__summary-icon {
                transition: transform 300ms ease-in-out;
            }

            > .accordion-item__summary-icon--default::before {
                content: "▼";
                opacity: 30%;
                line-height: 22px;
                font-size: 14px;
            }
        }

    }

    .accordion-item--open {
        height: calc(var(--summary-height) + var(--content-height) + 51px);
        background: var(--acco-lightest);

        > .accordion-item__summary > .accordion-item__summary-icon {
            transform: rotate(180deg);
        }

        p {
            margin-bottom: 15px;
            line-height: 25px;
        }

        li {
            margin-bottom: 10px;
            padding-left: 20px;

            &:before {
                content: '— ';
                margin-left: -20px;
                position: relative;
            }
        }
    }
}

</style>
